import { render, staticRenderFns } from "./ThreeColumnsContent.vue?vue&type=template&id=40c8efe1&"
import script from "./ThreeColumnsContent.vue?vue&type=script&lang=js&"
export * from "./ThreeColumnsContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CTAButton: require('/codebuild/output/src4108106887/src/eddys/components/global/CTAButton.vue').default,TitleRow: require('/codebuild/output/src4108106887/src/eddys/components/TitleRow.vue').default,ComponentBlockSingleColumnContent: require('/codebuild/output/src4108106887/src/eddys/components/component/block/SingleColumnContent.vue').default})
